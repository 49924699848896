import axios from 'axios'

class ConvenienceFeeFactory {

  async convenienceFee () {
    try {
      let url = '/convenience_fee'
      var response = await axios.get(url)
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async createConvenienceFee (data: any) {
    try {
      let url = '/convenience_fee'
      var response = await axios.post(url, data)
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async updateConvenienceFee (id: any, data: any) {
    try {
      let url = `/convenience_fee/${id}`
      var response = await axios.put(url, data)
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }
}

export let convenienceFeeFactory = new ConvenienceFeeFactory()
