<template>
  <input ref="inputRef" :value="formattedValue" class="form-control text-end" />
</template>

<script>
import { watch } from 'vue'
import useCurrencyInput from "vue-currency-input"

export default {
  name: "CurrencyInput",
  props: {
    modelValue: Number,
    options: {
        currency: 'IDR',
        precision: 0,
        distractionFree: false,
        valueAsInteger: true,
    },
    class: String,
  },
  setup (props) {
    const {
      inputRef,
      formattedValue,
      setOptions,
      setValue
    } = useCurrencyInput(props.options)

    watch(() => props.modelValue, (value) => {
      setValue(value)
    })

    watch(() => props.options, (options) => {
      setOptions(options)
    })

    return { inputRef, formattedValue }
  },
}
</script>
