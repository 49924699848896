const channels : any= {
    // channel string id
    "credit_card": "Kartu Kredit",
    "mandiri_clickpay": "Mandiri ClickPay",
    "cimb_clicks": "CIMB Clicks",
    "bca_klikbca": "BCA KlikBCA",
    "bca_klikpay": "BCA KlikPay",
    "bri_epay": "e-Pay BRI",
    "echannel": "E-Channel",
    "permata_va": "Virtual Account Permata",
    "bca_va": "Virtual Account BCA",
    "bni_va": "Virtual Account BNI",
    "other_va": "Virtual Account Lainnya",
    "gopay": "GoPay",
    "indomaret": "Indomaret",
    "alfamart": "Alfamart",
    "danamon_online": "Danamon Online",
    "akulaku": "Akulaku",
    "shopeepay": "ShopeePay"
}
export default channels
