import {
    Vue, Options
  } from 'vue-class-component'
  import Header from '@/components/Header.vue'
  import channels from '@/constants/channels'
  import swal from 'sweetalert'
  import * as bootstrap from 'bootstrap'
  import { convenienceFeeFactory } from '@/http/convenience-fee'
  import CurrencyInput from "@/components/CurrencyInput.vue"
  
  @Options({
    components: {
      Header,
      CurrencyInput
    },
    data() {
      return {
        convenienceFee: [],
        convenienceFeeActive: [],
        convenienceFeeNonActive: [],
        totalBill: 0,
        data: {
          id: '',
          channel_string_id: '',
          name: '',
          type: '',
          amount: 0,
          merchant_type: '',
          merchant_amount: 0,
          status: true
        },
        state: '',
        channels: channels
      }
    },
    methods: {
      save: function() {
        if (this.state == 'add') {
          this.data.status = (this.data.status) ? 1 : 0,
          Promise.all([
            convenienceFeeFactory.createConvenienceFee(this.data),
          ]).then(results => {
            if (results[0] != false) {
              swal("Sukses menyimpan biaya tambahan", "", "success")
              this.hideModal()
              this.loadData()
            } else {
              swal("Gagal menyimpan biaya tambahan", "", "error")
            }
          }).catch((e) => {
            swal("Gagal menyimpan biaya tambahan", "", "error")
            console.log(e)
          })
        } else {
          var data = {
            channel_string_id: this.data.channel_string_id,
            name: this.data.name,
            type: this.data.type,
            amount: this.data.amount,
            merchant_type: this.data.merchant_type,
            merchant_amount: this.data.merchant_amount,
            status: (this.data.status) ? 1 : 0
          }
          Promise.all([
            convenienceFeeFactory.updateConvenienceFee(this.data.id, data),
          ]).then(results => {
            if (results[0] != false) {
              swal("Sukses menyimpan biaya tambahan", "", "success")
              this.hideModal()
              this.loadData()
            } else {
              swal("Gagal menyimpan biaya tambahan", "", "error")
            }
          }).catch((e) => {
            swal("Gagal menyimpan biaya tambahan", "", "error")
            console.log(e)
          })
        }
      },
      edit: function(index: any, type: any) {
        if (type == 'active') {
          var convenienceFee = this.convenienceFeeActive[index]
        } else {
          var convenienceFee = this.convenienceFeeNonActive[index]
        }
        var data = {
          id: convenienceFee.id,
          channel_string_id: convenienceFee.channel_string_id,
          name: convenienceFee.name,
          type: convenienceFee.type,
          amount: convenienceFee.amount,
          merchant_type: convenienceFee.merchant_type,
          merchant_amount: convenienceFee.merchant_amount,
          status: (convenienceFee.status == 1) ? true : false,
        }
        this.data = data
        this.state = 'edit'
      },
      add: function() {
        this.data.channel_string_id = ''
        this.data.name = ''
        this.data.type = 'nominal'
        this.data.amount = 0
        this.data.merchant_type = 'nominal'
        this.data.merchant_amount = 0
        this.data.status = true
        this.state = 'add'
      },
      loadData: function() {
        Promise.resolve(convenienceFeeFactory.convenienceFee())
        .then(result => {
          this.convenienceFee = result.data.convenience_fee
          this.convenienceFeeActive = []
          this.convenienceFeeNonActive = []
          this.convenienceFee.forEach((types:any) => {
            if (types.status == 1) {
              this.convenienceFeeActive.push(types)
            } else {
              this.convenienceFeeNonActive.push(types)
            }
          })
        })
      },
      getChannel: function (value:any) {
        if (value in this.channels) {
          return this.channels[value]
        } else {
          return ''
        }
      },
      showModal: function() {
        this.myModal.show()
      },
      hideModal: function() {
        this.myModal.hide()
      }
    },
    computed: {
      config() {
        return {
          currency: 'IDR',
          precision: 0,
          distractionFree: false,
          valueAsInteger: true,
        }
      }
    },
    async mounted () {
      var myModal = document.getElementById('convenienceFeeModal')
      if (myModal != null) {
        this.myModal = new bootstrap.Modal(myModal)
      }
      await this.loadData()
    }
  })
  
  export default class ConvenienceFee extends Vue {}
  