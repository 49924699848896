<template>
  <Header title="Biaya Admin" link1="Master Tagihan" link2="Biaya Admin"></Header>
  <div class="row pt-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <router-link tag="a" to="/bill" class="btn btn-light rounded-pill"> <ion-icon name="arrow-back-outline"></ion-icon> </router-link>
              <h5 class="text-secondary d-inline">Master Tagihan</h5>
            </div>
            <div class="col-md-8">
              <div class="float-end">
              <button class="btn btn-circle btn-gradient" data-bs-toggle="modal" data-bs-target="#convenienceFeeModal" @click="add()"> <ion-icon name="add-circle-outline"></ion-icon> Tambah Biaya Admin</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row pt-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <table class="table table-borderless table-data fw-bold">
            <thead>
              <tr>
                <th>No</th>
                <th>Nama Channel</th>
                <th>Tipe Channel</th>
                <th>Tipe Biaya</th>
                <th>Nilai Biaya</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, index) in convenienceFeeActive" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ value.name }}</td>
                <td>{{ getChannel(value.channel_string_id) }}</td>
                <td>{{ value.type }}</td>
                <td><span v-if="value.type == 'nominal'">Rp</span>{{ value.amount }}<span v-if="value.type == 'percentage'">%</span></td>
                <td><span v-if="value.status == 1" class="label label-success fw-bold">Aktif</span></td>
                <td>
                   <button class="btn btn-sm btn-link-gray" data-bs-toggle="modal" data-bs-target="#convenienceFeeModal" @click="edit(index, 'active')">Edit <ion-icon name="create-outline"></ion-icon></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="row pt-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <table class="table table-borderless table-data fw-bold">
            <thead>
              <tr>
                <th>No</th>
                <th>Nama Channel</th>
                <th>Tipe Channel</th>
                <th>Tipe Biaya</th>
                <th>Nilai Biaya</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, index) in convenienceFeeNonActive" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ value.name }}</td>
                <td>{{ getChannel(value.channel_string_id) }}</td>
                <td>{{ value.type }}</td>
                <td><span v-if="value.type == 'nominal'">Rp</span>{{ value.amount }}<span v-if="value.type == 'percentage'">%</span></td>
                <td><span v-if="value.status == 0" class="label label-danger fw-bold">Tidak Aktif</span></td>
                <td>
                  <button class="btn btn-sm btn-link-gray" data-bs-toggle="modal" data-bs-target="#convenienceFeeModal" @click="edit(index, 'nonactive')">Edit <ion-icon name="create-outline"></ion-icon></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div id="convenienceFeeModal" class="modal" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Biaya Admin</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form class="row g-3">
            <div class="col-md-12 mb-3">
              <label for="" class="form-label">Tipe Channel</label>
              <select class="form-select" aria-label="Pilih Tipe Channel" v-model="data.channel_string_id">
                <option
                    v-for="(value, key) in channels"
                    :key="key"
                    :label="value"
                    :value="key">
                  </option>
              </select>
            </div>
            <div class="col-md-12 mb-3">
              <label for="" class="form-label">Nama Channel</label>
              <input type="text" class="form-control" v-model="data.name">
            </div>
            <div class="col-md-6 mb-3">
              <label for="" class="form-label">Biaya Admin</label>
                <div class="input-group mb-3">
                  <span v-if="data.type == 'nominal'" class="input-group-text">Rp</span>
                  <CurrencyInput v-if="data.type == 'nominal'" v-model="data.amount" :options="config" />
                  <span v-if="data.type == 'percentage'" class="input-group-text">%</span>
                  <input v-if="data.type == 'percentage'" type="number" class="form-control text-success text-end" v-model="data.amount">
                </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="" class="form-label">Tipe Biaya</label>
              <select class="form-select" v-model="data.type">
                <option value="percentage">Persentase</option>
                <option value="nominal">Nominal</option>
              </select>
            </div>
            <div class="col-md-6 mb-3">
              <label for="" class="form-label">Biaya Merchant</label>
                <div class="input-group mb-3">
                  <span v-if="data.merchant_type == 'nominal'" class="input-group-text">Rp</span>
                  <CurrencyInput v-if="data.merchant_type == 'nominal'" v-model="data.merchant_amount" :options="config" />
                  <span v-if="data.merchant_type == 'percentage'" class="input-group-text">%</span>
                  <input v-if="data.merchant_type == 'percentage'" type="number" class="form-control text-success text-end" v-model="data.merchant_amount">
                </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="" class="form-label">Tipe Biaya</label>
              <select class="form-select" v-model="data.merchant_type">
                <option value="percentage">Persentase</option>
                <option value="nominal">Nominal</option>
              </select>
            </div>
            <div class="col-md-12 mb-3">
              <label for="" class="form-label">Status</label>
              <div class="form-checkbox">
                <input class="form-check-input" type="checkbox" v-model="data.status">
                <span> Aktif</span>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal"> <ion-icon name="close-outline"></ion-icon> Tutup</button>
          <button v-bind:class="{'disabled': loadingSave }" type="button" class="btn btn-primary" @click="save()"> <i v-if="loadingSave" class="fa fa-spinner fa-pulse"></i><ion-icon v-if="!loadingSave" name="save-outline"></ion-icon> Simpan</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script language="ts" src="./scripts/convenience-fee.ts"></script>
